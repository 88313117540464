<template>
  <div class='container'>
    <b-loading :is-full-page='true' v-model='planLoading'></b-loading>
    <template>
      <div>
        <div class='plan-title'>
          <h1>{{ fullPlanData ? fullPlanData.planId : '' }}</h1>
        </div>

        <!-- Confirmation modal -->
        <div v-if="showConfirmationModal" class="modal no-padding" id="modal-warning">
          <div class="modal-content" id="confirmationModal">
            <p class="modal-title" id="confirmationModalTitle">
              Are you sure?
            </p>
            <p class="modal-text" id="confirmationModalText">
              Submitting will overwrite existing plan configuration.
            </p>

            <button class="btn-primary" @click="handleReviewConfirmation()">
              Yes, submit
            </button>
            <button class="btn-primary-outline" @click="showConfirmationModal = false">
              No, cancel
            </button>
          </div>
        </div>

        <div class='step-form' v-if='!planLoading'>
          <b-steps
            :animated='isAnimated'
            :has-navigation='true'
            @input='handleStepNavigation(lastStep)'
            v-model='activeStep'
          >

            <!-- PLAN DESIGN -->
            <!-- Note: Decremented step number by 1 for all steps since Network step no more exists-->
            <b-step-item
              label='Plan Design'
              icon='account-key'
              step='1'
              value='planDesign'
              :clickable='clickable || clickableArray.includes(0)'
              v-if='plan.steps.planDesign'
            >
              <MessageComponent v-if="isReview" :hasCloseMessage="false" :titleMessage="reviewMessageTitle" :textMessage="reviewMessageText" :messageType="'is-warning'" :isPlanSetup="true" />
              <PlanDesign ref='planDesign' />
            </b-step-item>

            <!-- NETWORK INFORMATION -->
            <!-- commented out the network step component -->
            <!-- <b-step-item
              label='Network'
              icon='account'
              step='2'
              value='network'
              :clickable='(clickable && !(hasErrorStep && hasErrorStep < 1)) || (!hasErrorStep && clickableArray.includes(1))'
              v-if='plan.steps.network'
            >
              <MessageComponent v-if="isReview" :hasCloseMessage="false" :titleMessage="reviewMessageTitle" :textMessage="reviewMessageText" :messageType="'is-warning'" :isPlanSetup="true" />
              <Network ref='network' />
            </b-step-item> -->

            <!-- COVERAGE DETAILS -->
            <b-step-item
              label='Vendor Details'
              icon='account-plus'
              step='2'
              value='coverageDetails'
              :clickable='(clickable && !(hasErrorStep && hasErrorStep < 1)) || (!hasErrorStep && clickableArray.includes(1))'
              v-if='plan.steps.coverageDetails'
            >
              <MessageComponent v-if="isReview" :hasCloseMessage="false" :titleMessage="reviewMessageTitle" :textMessage="reviewMessageText" :messageType="'is-warning'" :isPlanSetup="true" />
              <CoverageDetails ref='coverageDetails' />
            </b-step-item>

            <!-- MEMBER COSTS -->
            <b-step-item
              label='Member Costs'
              icon='account-plus'
              step='3'
              value='memberCosts'
              :clickable='(clickable && !(hasErrorStep && hasErrorStep < 2)) || (!hasErrorStep && clickableArray.includes(2))'
              v-if='plan.steps.memberCosts'
            >
              <MessageComponent v-if="isReview" :hasCloseMessage="false" :titleMessage="reviewMessageTitle" :textMessage="reviewMessageText" :messageType="'is-warning'" :isPlanSetup="true" />
              <MemberCosts ref='memberCosts' />
            </b-step-item>

            <!-- ACCUMULATORS -->
            <b-step-item
              label='Accumulators'
              icon='account-plus'
              step='4'
              value='accumulators'
              :clickable='(clickable && !(hasErrorStep && hasErrorStep < 3)) || (!hasErrorStep && clickableArray.includes(3))'
            >
              <MessageComponent v-if="isReview" :hasCloseMessage="false" :titleMessage="reviewMessageTitle" :textMessage="reviewMessageText" :messageType="'is-warning'" :isPlanSetup="true" />
              <Accumulators ref='accumulators' />
            </b-step-item>

            <!-- SETUP -->
            <b-step-item
              label='Setup'
              icon='account-plus'
              step='5'
              value='setup'
              :clickable='(clickable && !(hasErrorStep && hasErrorStep < 4)) || (!hasErrorStep && clickableArray.includes(4))'
              v-if='plan.steps.setup'
            >
              <Setup ref='setup' />
            </b-step-item>

            <!-- Steps nav -->
            <template
              slot='navigation'
              slot-scope='{ previous, next }'
              class='step-form-nav'
            >
              <div class='button-nav-wrapper'>
                <b-button
                  outlined
                  size='is-medium'
                  type='is-secondary'
                  :disabled='previous.disabled'
                  @click.prevent='previous.action'
                  class='toolbar-button prev-btn'
                >
                  Previous
                </b-button>
                <b-button
                  size='is-medium'
                  type='is-secondary'
                  @click.prevent='checkNext(next.action)'
                  class='toolbar-button next-btn'
                >
                  {{
                    activeStep == Object.keys(plan.steps).length - 1
                      ? 'Submit'
                      : 'Next'
                  }}
                </b-button>
              </div>
            </template>
          </b-steps>
          <div class='spacer'></div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import store from '@/store/store'
import planApi from '@/api/planApi'
import moment from 'moment'
import { mapActions } from 'vuex'

import MemberCosts from '@/components/member-costs'
import PlanDesign from '@/components/plan-design'
// import Network from '@/components/network'
import Setup from '@/components/setup'
import CoverageDetails from '@/components/coverage-details'
import Accumulators from '@/components/accumulators-config'

import MessageComponent from './../components/form/MessageComponent.vue'

// const PLAN_COMPLETED = 'Completed'

export default {
  name: 'plan-setup',
  async beforeMount () {
    const planBasicInfo = store.getters['plans/getPlan']
    const tableSourceType = store.getters['plans/getTableSourceType']
    await this.populateSettingData()
    const clientId = planBasicInfo.clientId
    const planId = planBasicInfo.planId
    if (tableSourceType === 'HRP') {
      this.loadHealthEdgePlan(planId, clientId)
    } else {
      this.loadPlan(planId, clientId)
    }
  },
  components: {
    MemberCosts,
    PlanDesign,
    // Network,
    Setup,
    CoverageDetails,
    Accumulators,
    MessageComponent
  },
  data () {
    const planBasicInfo = store.getters['plans/getPlan']
    const planId = planBasicInfo.planId
    const clientId = planBasicInfo.clientId
    const productTypePlanIdConfigType = planBasicInfo['productType#planId#configType']

    return {
      isReview: store.getters['plans/getIsReview'],
      showConfirmationModal: false,
      reviewPlanData: planBasicInfo,
      isAnimated: true,
      planLoading: true,
      plan: {
        planId: planId,
        productTypePlanIdConfigType: productTypePlanIdConfigType,
        clientId: clientId,
        steps: {
          // Set this to true as a default to avoid errors (will be set false in later check if necessary)
          planDesign: true,
          // network: false,
          coverageDetails: false,
          memberCosts: false,
          accumulators: false,
          setup: false
        }
      },
      // Format
      // API Object Field: App Object Field // Certainty level of API Object Field name
      sectionProperties: {
        coverageDetails: {
          telemedicine: 'telemedicine',
          pbmName: 'pbmName', // Certain
          pbmGroupId: 'groupId', // Mostly certain
          pbmAccountId: 'payerId', // Unconfirmed
          rxPCN: 'rxPCN', // Unknown
          rxBIN: 'rxBIN', // Unknown
          rxGRP: 'rxGRP', // Unknown
          phoneNumberRxBenefits: 'rxBenefitsPhone', // Certain
          pbmSiteURL: 'pbmSiteURL', // Unknown
          formularyUrl: 'formularyUrl' // Certain
        },
        memberCosts: {
          memberCostData: 'memberCostData', // Unknown
          rxCoverageList: 'rxCoverageData' // Certain
        },
        planDesign: {
          networkName: 'networkName', // Certain
          contactNumber: 'contactNumber', // Unknown
          planType: 'planTier', // Somewhat certain
          mfa: 'mfa', // Somewhat certain
          pcpRequired: 'pcpDesignation', // Certain
          virtualPrimaryCareEnabled: 'vpcEnabled',
          referrals: 'referrals', // Unknown
          referralDuration: 'referralsDays', // Certain
          referralsVisits: 'referralsVisits' // Certain
        },
        // network: {
        //   // networkType: 'networkType', // Unknown
        //   networkName: 'networkName', // Certain
        //   // wrapNetwork: 'wrapNetwork', // Unknown
        //   telemedicine: 'telemedicine' // Certain
        // },
        setup: {
          idCard: 'idCard', // Mostly certain - Appears to be a single object but I expected an array
          sbdUrl: 'spd', // Mostly certain - Note: Appears 'spd' is misspelled as 'sbd'
          sbcUrl: 'sbc' // Mostly certain
        }
      },
      fullPlanData: null,
      activeStep: 0,
      lastStep: 0,
      hasErrorStep: null,
      clickable: planBasicInfo.status === 'Completed',
      clickableArray: [0],
      reviewMessageTitle: 'Review before submitting',
      reviewMessageText: 'Review all fields prior to submitting to ensure input fields reflect the correct information.'
    }
  },
  computed: {},
  methods: {
    moment,
    ...mapActions(['plans/updateShowConfirmationModal', 'plans/updateIsReview']),
    handleStepNavigation (step) {
      const currStepName = Object.keys(this.plan.steps)[step]
      const ref = this.$refs[currStepName]
      const isValid = (ref && ref.saveSubmit())
      const isNext = (this.activeStep > step)

      if (isValid && (currStepName === 'coverageDetails' || currStepName === 'planDesign')) {
        // console.log('$Ref: ', this.$refs)
        this.$refs.memberCosts.saveSubmit()
      }

      return (isValid || !isNext) ? this.handleValidStep(step, isValid) : this.handleInvalidStep(step)
    },

    handleValidStep (step, isValid) {
      this.isAnimated = true
      this.lastStep = this.activeStep

      if (isValid && this.hasErrorStep === step) {
        this.hasErrorStep = null
      }

      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },

    handleInvalidStep (step) {
      this.isAnimated = false
      this.hasErrorStep = step
      this.$nextTick(() => {
        this.activeStep = this.lastStep
      })
    },

    handleReviewConfirmation () {
      this['plans/updateIsReview'](false)
      this.isReview = false
      this.showConfirmationModal = false

      return this.checkNext()
    },

    parseDate (date) {
      const locale = 'en-US'
      const nyTimeZone = 'America/New_York'
      const parsedDate = new Date(date)
      const localeParsedDate = new Date(parsedDate.toLocaleString(locale, { timeZone: nyTimeZone }))

      if (parsedDate.getDate() !== localeParsedDate.getDate()) {
        parsedDate.getDate() > localeParsedDate.getDate()
          ? parsedDate.setDate(parsedDate.getDate() + 1)
          : parsedDate.setDate(parsedDate.getDate() - 1)
      }

      return parsedDate.toLocaleString(locale, { timeZone: nyTimeZone })
    },

    checkNext (nextAction) {
      const currStepName = Object.keys(this.plan.steps)[this.activeStep]

      // Checks if its a review - if so, shows confirmation modal
      if (this.activeStep === Object.keys(this.plan.steps).length - 1 && this.isReview) {
        this.showConfirmationModal = true
        return
      }

      if (this.$refs[currStepName].saveSubmit()) {
        // Last step, time to submit!
        if (this.activeStep === Object.keys(this.plan.steps).length - 1) {
          const planDesignData =
            store.getters['plans/planDesign/getPlanDesignData']
          // const networkData = store.getters['plans/network/getNetworkData']
          const coverageDetailsData =
            store.getters['plans/coverageDetails/getCoverageDetailsData']
          const memberCostData =
            store.getters['plans/memberCosts/getMemberCostData']
          const checkedMemberCostData =
            store.getters['plans/memberCosts/getCheckedMemberCostData']
          const rxCoverageData =
            store.getters['plans/memberCosts/getRxCoverageData']
          const rxCoverageSpecialtyData =
            store.getters['plans/memberCosts/getRxCoverageSpecialtyData']
          const checkedRxCoverageData =
            store.getters['plans/memberCosts/getCheckedRxCoverageData']
          const checkedRxCoverageSpecialtyData =
            store.getters['plans/memberCosts/getCheckedRxCoverageSpecialtyData']
          const customMemberCostHeaders =
            store.getters['plans/memberCosts/getCustomMemberCostHeaders']
          const customRxHeaders =
            store.getters['plans/memberCosts/getCustomRxHeaders']
          const customRxSpecialtyHeaders =
            store.getters['plans/memberCosts/getCustomRxSpecialtyHeaders']
          const setupData = store.getters['plans/setup/getSetupData']
          const accumulatorsData = store.getters['plans/accumulators/getMappedAccumsData']

          // if (typeof (networkData.selectedNetworkNames) === 'string') {
          //   const temp = networkData.selectedNetworkNames
          //   networkData.selectedNetworkNames = [temp]
          // }

          const planFormData = {
            planDesign: planDesignData,
            // network: networkData,
            coverageDetails: coverageDetailsData,
            fullMemberCostData: memberCostData.map((x) => x),
            memberCosts: checkedMemberCostData.map((x) => {
              delete x.last
              return x
            }),
            rxCoverage: rxCoverageData.map((x) => {
              delete x.last
              return x
            }),
            rxCoverageSpecialtyList: rxCoverageSpecialtyData.map((x) => {
              delete x.last
              return x
            }),
            setup: setupData,
            accumulators: accumulatorsData
          }

          // Map plan design data
          this.fullPlanData.planName = planFormData.planDesign.planName.value
          this.fullPlanData.planStartDate = this.parseDate(planFormData.planDesign.startDate.value)
          this.fullPlanData.planEndDate = this.parseDate(planFormData.planDesign.endDate.value)
          this.fullPlanData.cmsId = planFormData.planDesign.cmsId.value
          // added Network/CMS name field to plan-design tab (earlier network name was in Network tab)
          this.fullPlanData.networkName = planFormData.planDesign.networkName.value
          this.fullPlanData.phoneNumberMemberServices =
            planFormData.planDesign.contactNumber.value
          this.fullPlanData.supportHours = planFormData.planDesign.supportHours.value
          this.fullPlanData.senderEmail = planFormData.planDesign.senderEmail.value
          this.fullPlanData.planTier = planFormData.planDesign.planTier ? planFormData.planDesign.planTier.value : ''
          this.fullPlanData.planType = planFormData.planDesign.planType.value
          this.fullPlanData.fullDirectory =
            planFormData.planDesign.fullDirectory
              ? planFormData.planDesign.fullDirectory.value
              : false
          this.fullPlanData.portalUrl = planFormData.planDesign.portalUrl.value
          this.fullPlanData.mfa = planFormData.planDesign.mfa.value
          this.fullPlanData.passwordHelperText = planFormData.planDesign.passwordHelperText.value ? planFormData.planDesign.passwordHelperText.value : 'Password must be at least 8 characters long and contain 1 or more of each of the following: lowercase letter, uppercase letter, symbol, and number.'
          this.fullPlanData.mfaPasswordRequirements = planFormData.planDesign.mfaPasswordRequirements.value
          this.fullPlanData.pcpRequired =
            planFormData.planDesign.pcpDesignation.value === 'required'
          this.fullPlanData.virtualPrimaryCareEnabled =
            planFormData.planDesign.vpcEnabled.value
          this.fullPlanData.referralRequired =
            planFormData.planDesign.referrals.value
          this.fullPlanData.referralAuthType =
            planFormData.planDesign.referralType.value
          this.fullPlanData.referralDuration =
            parseInt(planFormData.planDesign.referralsDays.value)
          this.fullPlanData.referralVisits =
            parseInt(planFormData.planDesign.referralsVisits.value)

          // Map network data - commented this out as Network step no longer exists
          // this.fullPlanData.networkType = planFormData.network.networkType
          // this.fullPlanData.networkName = planFormData.network.networkName
          // // this.fullPlanData.networkLink = planFormData.network.networkLink
          // // this.fullPlanData.wrapNetwork = planFormData.network.wrapNetwork
          // this.fullPlanData.telemedicine =
          //   planFormData.network.telemedicine === 'other'
          //     ? planFormData.network.telemedicineCustom
          //     : planFormData.network.telemedicine
          // // this.fullPlanData.networks =
          // //   planFormData.network.selectedNetworkNames
          // this.fullPlanData.telemedicinePlanCode =
          //   planFormData.network.telemedicine === 'Me MD' && planFormData.network.telemedicinePlanCode
          //     ? planFormData.network.telemedicinePlanCode
          //     : undefined

          // if (Array.isArray(this.fullPlanData.networks)) {
          //   this.networkArray = []
          //   for (
          //     let i = 0;
          //     i < this.fullPlanData.networks.length;
          //     i++
          //   ) {
          //     this.networkArray.push({
          //       networkName: this.fullPlanData.networks[i]
          //     })
          //   }
          // } else {
          //   this.networkArray = this.fullPlanData.networks
          // }
          // this.fullPlanData.networkIndicators = this.networkArray

          // Map coverage details data
          // added telemedicine data to coverage/vendor details tab (earlier telemedicine was in Network tab)
          this.fullPlanData.telemedicine = planFormData.coverageDetails.telemedicine === 'Other'
            ? planFormData.coverageDetails.telemedicineCustom
            : planFormData.coverageDetails.telemedicine
          this.fullPlanData.telemedicinePlanCode = planFormData.coverageDetails.telemedicine === 'Me MD' && planFormData.coverageDetails.telemedicinePlanCode
            ? planFormData.coverageDetails.telemedicinePlanCode
            : undefined
          this.fullPlanData.pbmName = planFormData.coverageDetails.pbmName
          this.fullPlanData.pbmGroupId = planFormData.coverageDetails.groupId
          this.fullPlanData.pbmPayerId = planFormData.coverageDetails.payerId
          this.fullPlanData.pbmRxPCN = planFormData.coverageDetails.rxPCN
          this.fullPlanData.pbmRxBIN = planFormData.coverageDetails.rxBIN
          this.fullPlanData.pbmRxGRP = planFormData.coverageDetails.rxGRP
          this.fullPlanData.phoneNumberRxBenefits =
            planFormData.coverageDetails.rxBenefitsPhone
          this.fullPlanData.pbmLink = planFormData.coverageDetails.pbmSiteURL
          this.fullPlanData.formularyUrl =
            planFormData.coverageDetails.formularyUrl
          this.fullPlanData.idCardStatement =
            planFormData.coverageDetails.idCardStatement

          // Map member cost data
          // this.fullPlanData.memberCostList = planFormData.memberCosts
          // Will be individualMedicalCoverageList and familyMedicalCoverageList. Use column header as key for tier1 and tier2. If 'blank', omit
          this.fullPlanData.fullMedicalCoverageList =
            planFormData.fullMemberCostData.map((item) => {
              return {
                ...item,
                isChecked: checkedMemberCostData.includes(item)
              }
            })

          this.fullPlanData.individualMedicalCoverageList =
            planFormData.memberCosts
              .map((row) => {
                return {
                  category: row.category,
                  individualTier1: row.individualTier1,
                  individualTier2: row.individualTier2
                }
              })
              .filter((row) => {
                return row.individualTier1 || row.individualTier2
              })
          this.fullPlanData.familyMedicalCoverageList = planFormData.memberCosts
            .map((row) => {
              return {
                category: row.category,
                familyTier1: row.familyTier1,
                familyTier2: row.familyTier2
              }
            })
            .filter((row) => {
              return row.familyTier1 || row.familyTier2
            })

          // build out the coverage summary list
          this.fullPlanData.coverageSummaryList = []
          this.familyCoverageListItem = {
            coverageType: 'Family',
            coverageItemList: []
          }
          this.individualCoverageListItem = {
            coverageType: 'Individual',
            coverageItemList: []
          }

          // Fill Family and Individual Objects
          for (this.covg in this.fullPlanData.fullMedicalCoverageList) {
            if (!this.fullPlanData.fullMedicalCoverageList[this.covg].isChecked) {
              continue
            }

            // Family
            this.familyObj = {}
            this.familyObj.care = this.fullPlanData.fullMedicalCoverageList[this.covg].category
            this.familyObj[customMemberCostHeaders.tier1] = this.fullPlanData.fullMedicalCoverageList[this.covg].familyTier1

            if (customMemberCostHeaders.tier2.trim() !== '') {
              if (!this.fullPlanData.fullMedicalCoverageList[this.covg].familyTier2 !== '') {
                this.familyObj[customMemberCostHeaders.tier2] =
                  this.fullPlanData.fullMedicalCoverageList[this.covg].familyTier2
              }
            }

            this.familyCoverageListItem.coverageItemList.push(this.familyObj)

            // Individual
            this.individualObj = {}
            this.individualObj.care = this.fullPlanData.fullMedicalCoverageList[this.covg].category
            this.individualObj[customMemberCostHeaders.tier1] = this.fullPlanData.fullMedicalCoverageList[this.covg].individualTier1

            if (customMemberCostHeaders.tier2.trim() !== '') {
              if (this.fullPlanData.fullMedicalCoverageList[this.covg].individualTier2 !== '') {
                this.individualObj[customMemberCostHeaders.tier2] =
                  this.fullPlanData.fullMedicalCoverageList[this.covg].individualTier2
              }
            }

            this.individualCoverageListItem.coverageItemList.push(this.individualObj)
          }

          this.fullPlanData.coverageSummaryList.push(this.familyCoverageListItem)
          this.fullPlanData.coverageSummaryList.push(this.individualCoverageListItem)

          if (customMemberCostHeaders.tier2 && customMemberCostHeaders.tier2 !== '') {
            this.fullPlanData.planTier = `${customMemberCostHeaders.tier1}/${customMemberCostHeaders.tier2}`
          } else {
            this.fullPlanData.planTier = customMemberCostHeaders.tier1
          }

          this.fullPlanData.rxCoverageList = planFormData.rxCoverage.map(
            (rxCovRow) => {
              return {
                row: [
                  rxCovRow.category,
                  rxCovRow.userInput1,
                  rxCovRow.userInput2,
                  checkedRxCoverageData.includes(rxCovRow)
                ]
              }
            }
          )
          this.fullPlanData.rxCoverageList.unshift({
            row: [
              '',
              customRxHeaders.customHeader1,
              customRxHeaders.customHeader2
            ]
          })

          this.fullPlanData.rxCoverageSpecialtyList =
            planFormData.rxCoverageSpecialtyList.map((rxCovSpecRow) => {
              return {
                row: [
                  rxCovSpecRow.category,
                  rxCovSpecRow.userInput1,
                  rxCovSpecRow.userInput2,
                  checkedRxCoverageSpecialtyData.includes(rxCovSpecRow)
                ]
              }
            })
          this.fullPlanData.rxCoverageSpecialtyList.unshift({
            row: [
              '',
              customRxSpecialtyHeaders.customHeader1,
              customRxSpecialtyHeaders.customHeader2
            ]
          })

          // Map accumulators data
          this.fullPlanData.accumsConfig = {}
          for (const field in planFormData.accumulators) {
            this.fullPlanData.accumsConfig[field] = planFormData.accumulators[field]
          }

          // Map setup data
          this.fullPlanData.idCardLogoUrl =
            planFormData.setup.idCard?.objectUrl // idCardLogoUrl
          this.fullPlanData.spdUrl = planFormData.setup.spd?.objectUrl // spdUrl
          this.fullPlanData.sbcUrl = planFormData.setup.sbc?.objectUrl // sbcUrl

          // Set other update values
          this.fullPlanData.started = true
          this.fullPlanData.completed = true

          const updatedPlanData = this.fullPlanData
          console.log('Full plan data: ', updatedPlanData)
          planApi
            .updatePlan(updatedPlanData, this.plan.planId, this.plan.clientId)
            .then((res) => {
              this.$buefy.toast.open({
                message: 'Plan saved successfully!',
                type: 'is-success',
                duration: 5000,
                position: 'is-top'
              })
              store.dispatch('plans/updatePlan', updatedPlanData)
              this.$router.push({ path: '/plans' })
            })
            .catch((err) => {
              console.error(err)
            })
        } else if (nextAction) {
          this.clickableArray.push(this.activeStep + 1)
          nextAction()
          window.scrollTo(0, 0)
        }
      }
    },

    loadHealthEdgePlan (planId, clientId) {
      if (this.isReview) {
        return this.setStorePlanData(this.reviewPlanData)
      }

      return planApi.getHealthEdgePlan(planId, clientId)
        .then((res) => this.setStorePlanData(res.data))
        .catch((err) => console.error('Error loading plan', err))
    },

    loadPlan (planId, clientId) {
      if (this.isReview) {
        return this.setStorePlanData(this.reviewPlanData)
      }

      return planApi.getPlan(planId, clientId)
        .then((res) => this.setStorePlanData(res.data))
        .catch((err) => console.error('Error loading plan', err))
    },

    setStorePlanData (planData) {
      store.dispatch('plans/updatePlan', planData)
      this.fullPlanData = planData
      this.chooseSteps()

      // set all the data in the store to the plan we just received
      const planDesign = {
        planName: { display: '', value: planData.planName },
        startDate: {
          display: '',
          value: planData.planStartDate
            ? new Date(planData.planStartDate)
            : undefined
        },
        endDate: {
          display: '',
          value: planData.planEndDate ? new Date(planData.planEndDate) : undefined
        },
        cmsId: { display: '', value: planData.cmsId },
        // added Network/CMS name data to planDesign store
        networkName: { display: '', value: planData.networkName },
        contactNumber: {
          display: planData.phoneNumberMemberServices,
          value: planData.phoneNumberMemberServices
        },
        supportHours: { display: planData.supportHours, value: planData.supportHours },
        portalUrl: { display: planData.portalUrl, value: planData.portalUrl },
        senderEmail: {
          display: planData.senderEmail,
          value: planData.senderEmail
            ? planData.senderEmail
            : 'The Centivo Team <No_Reply@centivo.com>'
        },
        // planTier: { display: planData.planTier, value: planData.planTier },
        planType: { display: planData.planType, value: planData.planType },
        fullDirectory: {
          display: '',
          value:
            planData.planType === 'OpenAccess' && planData.fullDirectory
              ? planData.fullDirectory
              : false
        },
        mfa: { display: planData.mfa, value: planData.mfa },
        pcpDesignation: {
          display: '',
          value:
            planData.pcpRequired === undefined
              ? undefined
              : planData.pcpRequired === true
                ? 'required'
                : 'none'
        },
        vpcEnabled: {
          display: '',
          value: planData.virtualPrimaryCareEnabled
        },
        referrals: { display: '', value: planData.referralRequired },
        referralType: {
          display: planData.referralAuthType,
          value: planData.referralAuthType
        },
        referralsDays: {
          display: !planData.referralDuration
            ? undefined
            : planData.referralDuration !== 365
              ? 'custom'
              : planData.referralRequired
                ? planData.referralDuration
                : undefined,
          value: planData.referralDuration
        },
        referralsVisits: {
          display: !planData.referralVisits
            ? undefined
            : planData.referralVisits !== 'na'
              ? 'custom'
              : 'na',
          value: planData.referralVisits
        },
        mfaPasswordRequirements: {
          display: '',
          value: planData.mfaPasswordRequirements
        },
        passwordHelperText: {
          display: '',
          value: planData.passwordHelperText
        }
      }
      store.dispatch('plans/planDesign/setPlanDesignData', planDesign)

      // commented this code block as Network store is no more needed
      // const networkData = {
      //   networkType: planData.networkType,
      //   networkName: planData.networkName,
      //   wrapNetwork: planData.wrapNetwork,
      //   telemedicine: !planData.telemedicine
      //     ? undefined
      //     : planData.telemedicine === '98point6'
      //       ? planData.telemedicine
      //       : planData.telemedicine === 'MDLIVE'
      //         ? planData.telemedicine
      //         : planData.telemedicine === 'Doctors on Demand'
      //           ? planData.telemedicine
      //           : planData.telemedicine === 'Me MD'
      //             ? planData.telemedicine
      //             : planData.telemedicine === 'Premise'
      //               ? planData.telemedicine
      //               : 'other',
      //   telemedicineCustom:
      //     planData.telemedicine === '98point6'
      //       ? 'Other'
      //       : planData.telemedicine === 'MDLIVE'
      //         ? 'Other'
      //         : planData.telemedicine === 'Doctors on Demand'
      //           ? 'Other'
      //           : planData.telemedicine === 'Me MD'
      //             ? 'Other'
      //             : planData.telemedicine === 'Premise'
      //               ? 'Other'
      //               : planData.telemedicine,
      //   selectedNetworkNames: planData.networks,
      //   telemedicinePlanCode: planData.telemedicinePlanCode
      // }
      // store.dispatch('plans/network/setNetworkData', networkData)

      const coverageData = {
        pbmName: planData.pbmName,
        groupId: planData.pbmGroupId,
        payerId: planData.pbmPayerId,
        rxPCN: planData.pbmRxPCN,
        rxBIN: planData.pbmRxBIN,
        rxGRP: planData.pbmRxGRP,
        rxBenefitsPhone: planData.phoneNumberRxBenefits,
        pbmSiteURL: planData.pbmLink,
        formularyUrl: planData.formularyUrl,
        idCardStatement: planData.idCardStatement,
        // added telemedicine data to coverageDetails store
        telemedicine: !planData.telemedicine
          ? undefined
          : planData.telemedicine === '98point6'
            ? planData.telemedicine
            : planData.telemedicine === 'MDLIVE'
              ? planData.telemedicine
              : planData.telemedicine === 'Doctors on Demand'
                ? planData.telemedicine
                : planData.telemedicine === 'Me MD'
                  ? planData.telemedicine
                  : planData.telemedicine === 'Premise'
                    ? planData.telemedicine
                    : 'Other',
        telemedicineCustom:
          planData.telemedicine === '98point6'
            ? 'Other'
            : planData.telemedicine === 'MDLIVE'
              ? 'Other'
              : planData.telemedicine === 'Doctors on Demand'
                ? 'Other'
                : planData.telemedicine === 'Me MD'
                  ? 'Other'
                  : planData.telemedicine === 'Premise'
                    ? 'Other'
                    : planData.telemedicine,
        telemedicinePlanCode: planData.telemedicine === 'Me MD' ? planData.telemedicinePlanCode : undefined
      }
      store.dispatch(
        'plans/coverageDetails/setCoverageDetailsData',
        coverageData
      )

      const tiers = planData.planTier ? planData.planTier.split('/') : []
      store.dispatch('plans/memberCosts/setCustomMemberCostHeaders', {
        tier1: tiers[0] ? tiers[0].trim() : '',
        tier2: tiers[1] ? tiers[1].trim() : ''
      })

      if (planData.fullMedicalCoverageList) {
        const defaultMemberCostsCategories = store.getters['plans/memberCosts/getDefaultMemberCostList']

        const memberCosts = planData.fullMedicalCoverageList.map((row) => {
          return {
            category: row.category,
            individualTier1: row.individualTier1,
            individualTier2: row.individualTier2,
            familyTier1: row.familyTier1,
            familyTier2: row.familyTier2,
            static: row.static,
            isChecked: row.isChecked
          }
        })

        const checkedMemberCost = planData.fullMedicalCoverageList
          .map((row) => {
            return {
              category: row.category,
              individualTier1: row.individualTier1,
              individualTier2: row.individualTier2,
              familyTier1: row.familyTier1,
              familyTier2: row.familyTier2,
              static: row.static,
              isChecked: row.isChecked
            }
          })
          .filter((row) => {
            return planData.familyMedicalCoverageList.find(
              (x) => x.category === row.category
            ) !== undefined
              ? row
              : null
          })

        defaultMemberCostsCategories.forEach((defaultObj, index) => {
          const listHasCategory = memberCosts.find((obj) => obj.category.toLowerCase() === defaultObj.category.toLowerCase())

          if (!listHasCategory && defaultObj.category === 'Diagnostic Testing') {
            const testsIndex = memberCosts.findIndex((obj) => obj.category.toLowerCase() === 'tests')
            if (testsIndex >= 0) {
              memberCosts[testsIndex].category = 'Diagnostic Testing'
            }
            return
          }

          if (!listHasCategory && defaultObj.category === 'Outpatient Facility') {
            const outPatientFacilityIndex = memberCosts.findIndex((obj) => obj.category.toLowerCase() === 'outpatient surgery')
            if (outPatientFacilityIndex >= 0) {
              memberCosts[outPatientFacilityIndex].category = 'Outpatient Facility'
            }
            return
          }

          if (!listHasCategory && defaultObj.category === '') {
            return
          }

          if (!listHasCategory) {
            memberCosts.splice(index, 0, defaultObj)
          }
        })

        memberCosts.map((row, index) => {
          memberCosts[index].id = index
        })

        store.dispatch('plans/memberCosts/setMemberCostData', memberCosts)
        store.dispatch(
          'plans/memberCosts/setCheckedMemberCostData',
          checkedMemberCost
        )
      }

      if (planData.rxCoverageList && planData.rxCoverageList.length > 1) {
        var rxHeaders = planData.rxCoverageList.splice(0, 1)
        const rxCoverageList = planData.rxCoverageList.map((record) => {
          return {
            category: record.row[0],
            userInput1: record.row[1],
            userInput2: record.row[2],
            isChecked: record.row[3] ?? true
          }
        })
        // pop the last empty record off if there is one because
        // we don't want it to be checked. We will add it back
        // down below
        if (!rxCoverageList[rxCoverageList.length - 1]?.category) {
          var newList = rxCoverageList.pop()
          store.dispatch(
            'plans/memberCosts/setCheckedRxCoverageData',
            newList
          )
        } else {
          store.dispatch(
            'plans/memberCosts/setCheckedRxCoverageData',
            rxCoverageList
          )
        }

        // we always want to append an empty record at the end of the list
        // so the user has a new row to input data
        rxCoverageList.push({
          category: '',
          userInput1: '',
          userInput2: '',
          last: true
        })

        store.dispatch(
          'plans/memberCosts/setRxCoverageData',
          rxCoverageList
        )

        var mappedHeaders = rxHeaders.map((record) => {
          return {
            customHeader1: record.row[1],
            customHeader2: record.row[2]
          }
        })
        store.dispatch(
          'plans/memberCosts/setCustomRxHeaders',
          mappedHeaders[0]
        )
      }

      if (
        planData.rxCoverageSpecialtyList &&
        planData.rxCoverageSpecialtyList.length > 1
      ) {
        rxHeaders = planData.rxCoverageSpecialtyList.splice(0, 1)
        const rxCoverageSpecialtyList =
          planData.rxCoverageSpecialtyList.map((record) => {
            return {
              category: record.row[0],
              userInput1: record.row[1],
              userInput2: record.row[2],
              isChecked: record.row[3] ?? true
            }
          })
        // pop the last empty record off if there is one because
        // we don't want it to be checked. We will add it back
        // down below
        if (
          !rxCoverageSpecialtyList[rxCoverageSpecialtyList.length - 1]
            ?.category
        ) {
          newList = rxCoverageSpecialtyList.pop()
          store.dispatch(
            'plans/memberCosts/setCheckedRxCoverageSpecialtyData',
            newList
          )
        } else {
          store.dispatch(
            'plans/memberCosts/setCheckedRxCoverageSpecialtyData',
            rxCoverageSpecialtyList
          )
        }

        // we always want to append an empty record at the end of the list
        // so the user has a new row to input data
        rxCoverageSpecialtyList.push({
          category: '',
          userInput1: '',
          userInput2: '',
          last: true
        })

        store.dispatch(
          'plans/memberCosts/setRxCoverageSpecialtyData',
          rxCoverageSpecialtyList
        )

        mappedHeaders = rxHeaders.map((record) => {
          return {
            customHeader1: record.row[1],
            customHeader2: record.row[2]
          }
        })
        store.dispatch(
          'plans/memberCosts/setCustomRxSpecialtyHeaders',
          mappedHeaders[0]
        )
      }

      // Set accums config
      const accumulatorsData = {}
      const accumsConfig = planData.accumsConfig ?? {}

      Object.keys(accumsConfig).map((key) => {
        accumulatorsData[key] = accumsConfig[key]
      })

      store.dispatch('plans/accumulators/setAccumulatorsData', accumulatorsData)

      var addedList = {}
      if (planData.idCard && planData.idCardLogoUrl) {
        addedList.idCard = planData.idCard
        addedList.idCard = {
          name: planData.idCardLogoUrl.split('/')[
            planData.idCardLogoUrl.split('/').length - 1
          ],
          objectUrl: planData.idCardLogoUrl
        }
      }
      if (planData.sbcUrl) {
        addedList.sbc = {
          name: planData.sbcUrl.split('/')[
            planData.sbcUrl.split('/').length - 1
          ],
          objectUrl: planData.sbcUrl
        }
      }
      if (planData.spdUrl) {
        addedList.spd = {
          name: planData.spdUrl.split('/')[
            planData.spdUrl.split('/').length - 1
          ],
          objectUrl: planData.spdUrl
        }
      }

      store.dispatch('plans/setup/setSetupData', addedList)

      this.planLoading = false
    },
    chooseSteps () {
      const planDataKeys = Object.keys(this.fullPlanData)

      Object.keys(this.sectionProperties).forEach((section) => {
        const sectionDataKeys = Object.keys(this.sectionProperties[section])

        if (sectionDataKeys.some((field) => planDataKeys.includes(field))) {
          this.plan.steps[section] = true
        } else {
          this.plan.steps[section] = false
        }
      })
    },
    async populateSettingData () {
      await store
        .dispatch('plans/getSettings')
        .then((res) => {
          store.dispatch('plans/setPlanTierNames', res.planTierNames)
          // store.dispatch('plans/setNetworkTypes', res.networkTypes)
          // store.dispatch(
          //   'plans/setCentivoNetworkNames',
          //   res.networkNames['Centivo Network']
          // )
          store.dispatch('plans/setRbrNetworkNames', res.networkNames.RBR)
          store.dispatch(
            'plans/setThirdPartyNetworkNames',
            res.networkNames['Third-party']
          )
          // store.dispatch('plans/setWrapNetworks', res.wrapNetworks)
          store.dispatch('plans/setTelemedicineOptions', res.telemedicine)
          store.dispatch('plans/setPbmNames', res.pbmNames)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }
}
</script>

<style lang='scss'>
.plan-title h1 {
  font-size: 32px;
  margin-top: 24px;
  margin-bottom: 24px;
  font-weight: bold;
}

.b-steps .step-items {
  width: 65%;
  margin: auto;
  margin-bottom: 64px;
}

.b-steps .step-details {
  margin-top: 8px;
}

.b-steps .step-content .step-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -10px;
}

.spacer {
  height: 100px;
}

.button-nav-wrapper {
  width: 35%;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.toolbar-button {
  width: 120px;
}

.prev-btn {
  margin-right: 32px;
}

@media screen and (max-width: 1200px) {
  .button-nav-wrapper {
    width: 50%;
  }
}

@media screen and (max-width: 500px) {
  .button-nav-wrapper {
    width: 80%;
  }
}
</style>
